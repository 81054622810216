import React, { useState, useEffect } from "react";
import {
  Form,
  useToaster,
  Message,
  Button,
  Modal,
  Schema,
  ButtonToolbar,
  Placeholder,
} from "rsuite";
import { SelectPicker } from "rsuite";
import Api from "../Backend/Backend";

const RemoveWordpress = () => {
  const toaster = useToaster();
  const [wordpressName, setWordpressName] = useState("");
  const [selected, setSelected] = useState(null);
  const [userId, setUserId] = useState(sessionStorage.getItem("username"));
  const [wordpressList, setWordpressList] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const [wordpressId, setWordpressId] = useState(null);
  const backend = new Api();
  const [open, setOpen] = React.useState(false);
  const initFormData = {
    website_name: "",
    user_url: "",
    user_name: "",
    user_pass: "",
    account_id: wordpressId,
  };

  const [formdata, setFormdata] = useState(initFormData);

  const model = Schema.Model({
    website_name: Schema.Types.StringType(),
    user_name: Schema.Types.StringType().isRequired("Username is required"),
    user_url: Schema.Types.StringType().isURL("Please enter a valid URL."),
    user_pass: Schema.Types.StringType().isRequired("Password is required."),
  });

  useEffect(() => {
    backend
      .getWordpressAccounts(sessionStorage.getItem("username"))
      .then((response) => {
        setWordpressList(response.data);
      });
  }, [deleted]);

  const data = wordpressList.map((item) => ({
    label: item.website_name,
    value: item.account_id,
  }));

  const successMessage = (
    <Message showIcon type="success" header="Success">
      Account has been deleted/Edited!
    </Message>
  );
  const failureMessage = (
    <Message showIcon type="error" header="Error">
      Cannot delete/edit account!
    </Message>
  );

  const handleSubmit = () => {
    formdata.account_id = wordpressId;
    backend
      .editAccount(formdata)
      .then((res) => {
        if (res.status === 200) {
          toaster.push(successMessage, {
            placement: "topEnd",
          });
        }
      })
      .catch(() => {
        toaster.push(failureMessage, {
          placement: "topEnd",
        });
      });
  }

  const handleDelete = () => {
    backend
      .deleteAccount(wordpressId)
      .then((res) => {
        if (res.status === 200) {
          toaster.push(successMessage, {
            placement: "topEnd",
          });
        }
        setDeleted(!deleted);
      })
      .catch(() => {
        toaster.push(failureMessage, {
          placement: "topEnd",
        });
      });
  };

  const handleSelect = (account) => {
    console.log(account);
    setWordpressName(account.website_name);
    setWordpressId(account.account_id);
  };

  return (
    <div className="container text-center mt-5 ">
      <h3>Delete a Wordpress Account</h3>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <SelectPicker
          value={selected}
          onChange={(e) => {
            setSelected(e);
            wordpressList.map((item) =>
              item.account_id == e ? handleSelect(item) : null
            );
          }}
          data={data}
          placement="bottomStart"
          style={{ width: 224, marginTop: "30px", paddingBottom: "0px" }}
        />
        {wordpressId ? (
          <>
            <ButtonToolbar>
              <Button
                onClick={() => handleDelete()}
                style={{
                  backgroundColor: "#3498ff",
                  color: "white",
                  marginTop: "20px",
                }}
              >
                Delete
              </Button>
              <Button
                onClick={() => setOpen(!open)}
                style={{
                  backgroundColor: "#40aba7",
                  color: "white",
                  marginTop: "20px",
                }}
              >
                Edit
              </Button>
            </ButtonToolbar>
          </>
        ) : (
          <>
            <ButtonToolbar>
              <Button
                disabled
                style={{
                  color: "gray",
                  marginTop: "20px",
                }}
              >
                Delete
              </Button>
              <Button
                disabled
                style={{
                  color: "gray",
                  marginTop: "20px",
                }}
              >
                Edit
              </Button>
            </ButtonToolbar>
          </>
        )}
      </div>
      <Modal size="xs" open={open}>
        <Modal.Header>
          <Modal.Title>Edit Wordpress Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
          formValue={formdata}
          onChange={(formdata) => setFormdata(formdata)}
          model={model}
          >
            <Form.Group name="website_name">
              <Form.ControlLabel>Website Name</Form.ControlLabel>
              <Form.Control
                name="website_name"
                
              />
            </Form.Group>
            <Form.Group controlId="user_url">
              <Form.ControlLabel>Url</Form.ControlLabel>
              <Form.Control name="user_url" />
            </Form.Group>
            <Form.Group controlId="user_name">
              <Form.ControlLabel>Username</Form.ControlLabel>
              <Form.Control
                name="user_name"
                type="email"
                
              />
            </Form.Group>
            <Form.Group controlId="user_pass">
              <Form.ControlLabel>Password</Form.ControlLabel>
              <Form.Control
                name="user_pass"
                type="password"
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group>
              <ButtonToolbar>
                <Button appearance="primary" onClick={() => handleSubmit()}>
                  Submit
                </Button>
                <Button appearance="default" onClick={() => setOpen(!open)}>
                  Cancel
                </Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RemoveWordpress;
