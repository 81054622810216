let env = "production";
//let env = "local";
export let origin = env === "production" ? "" : "http://localhost:8000";
// export let origin = process.env.REACT_APP_MAIN_END_POINT;

export const ApiUrls = {
    GetArticle: origin + "/api/get-article",
    GetBulkArticle: origin + "/api/get-bulk-articles",
    GenerateArticle: origin + "/api/generate-article",
    GenerateBulkArticles: origin + "/api/generate-bulk-article",
    GetProgress: origin + "/api/get-progress",
    UserLogin: origin + "/api/login",
    GetArticles: origin + "/api/get-articles",
    AddWordpress: origin + "/api/login-wordpress",
    GetWordpressAccounts: origin + "/api/get-wordpress-accounts",
    EditArticle: origin + "/api/edit-article",
    PublishSoloArticle: origin + "/api/post-articles",
    CreateProject: origin + "/api/create-project",
    ListProject: origin + "/api/list-projects",
    DeleteArticle: origin + "/api/delete-article",
    DeleteProject: origin + "/api/delete-project",
    DeleteAccount: origin + "/api/delete-account",
    EditAccount: origin + "/api/edit-account",
    DownloadArticles: origin + "/api/download-article",
};
