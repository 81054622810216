import { useState, forwardRef } from "react";
import "rsuite/dist/rsuite.min.css";
import { Container, Sidebar, Sidenav, Content, Navbar, Nav } from "rsuite";
import CogIcon from "@rsuite/icons/legacy/Cog";
import AngleLeftIcon from "@rsuite/icons/legacy/AngleLeft";
import AngleRightIcon from "@rsuite/icons/legacy/AngleRight";
import DashboardIcon from "@rsuite/icons/Dashboard";
import PageIcon from "@rsuite/icons/Page";
import GlobalIcon from "@rsuite/icons/Global";
import { Link } from "react-router-dom";

const NavLink = forwardRef(({ href, children, ...rest }, ref) => (
    <Link ref={ref} to={href} {...rest}>
        {children}
    </Link>
));

const NavToggle = ({ expand, onChange }) => {
    return (
        <Navbar appearance="subtle" className="nav-toggle">
            <Nav>
                <Nav.Menu
                    noCaret
                    placement="topStart"
                    trigger="click"
                    title={
                        <CogIcon style={{ width: 20, height: 20 }} size="sm" />
                    }
                >
                    <Nav.Item>Logout</Nav.Item>
                </Nav.Menu>
            </Nav>

            <Nav pullRight>
                <Nav.Item
                    onClick={onChange}
                    style={{ width: 56, textAlign: "center" }}
                >
                    {expand ? <AngleLeftIcon /> : <AngleRightIcon />}
                </Nav.Item>
            </Nav>
        </Navbar>
    );
};

const SidebarLayout = ({ children }) => {
    const [expand, setExpand] = useState(false);
    return (
        <div className="app-bg">
            <Container>
                <Sidebar
                    style={{ display: "flex", flexDirection: "column" }}
                    width={expand ? 240 : 50}
                    collapsible
                >
                    <Sidenav
                        expanded={expand}
                        defaultOpenKeys={["1"]}
                        appearance="subtle"
                    >
                        <Sidenav.Body>
                            <Nav>
                                <Nav.Item
                                    eventKey="1"
                                    icon={<DashboardIcon />}
                                    as={NavLink}
                                    href="/dashboard"
                                >
                                    Generator
                                </Nav.Item>
                                <Nav.Item
                                    eventKey="2"
                                    icon={<PageIcon />}
                                    as={NavLink}
                                    href="/articles"
                                >
                                    Articles
                                </Nav.Item>
                                <Nav.Item
                                    eventKey="3"
                                    icon={<GlobalIcon />}
                                    as={NavLink}
                                    href="/wordpress-settings"
                                >
                                    Wordpress
                                </Nav.Item>
                            </Nav>
                        </Sidenav.Body>
                    </Sidenav>
                    <NavToggle
                        expand={expand}
                        onChange={() => setExpand(!expand)}
                    />
                </Sidebar>

                <Container>
                    <Content>{children}</Content>
                </Container>
            </Container>
        </div>
    );
};

export default SidebarLayout;
