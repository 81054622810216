import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArticleInput from "../Components/ArticleInput";
import ArticleStructure from "../Components/ArticleStructure";
import ArticleStyle from "../Components/ArticleStyle";
import BackgroundIcons from "../Components/BackgrondIcons";
import Modal from "../Components/Modal";
import { useState } from "react";
import "./Home.css";
import Api from "../Backend/Backend";
import ProgressBar from "@ramonak/react-progress-bar";
import parse, { domToReact } from "html-react-parser";
import { Message, useToaster } from "rsuite";
import { SelectPicker } from "rsuite";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const Home = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setUser(user);
            } else {
                navigate("/");
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    const backend = new Api();

    // const [selected, setSelected] = React.useState(null);
    const [projectList, setProjectList] = useState([]);

    useEffect(() => {
        backend
            .listProject(sessionStorage.getItem("username"))
            .then((response) => {
                setProjectList(response.data);
            });
    }, []);

    const data = projectList.map((item) => ({
        label: item.project_name,
        value: item.project_id,
    }));

    const toaster = useToaster();
    const [selectedModelOption, setSelectedModelOption] = useState({
        value: 0,
        label: "Davinci",
    });
    const [selectedWordsOption, setSelectedWordsOption] = useState({
        value: 300,
        label: "300",
    });
    const [selectedProject, setSelectedProject] = useState({
        value: "default",
        label: "default",
        project_id: 0,
    });
    const [selectedToneOption, setSelectedToneOption] = useState({
        value: "Neil Patel",
        label: "Neil Patel",
    });
    const [selectedBrandStyleOption, setSelectedBrandStyleOption] = useState({
        value: "Buzzfeed",
        label: "Buzzfeed",
    });
    const [keyword, setKeyword] = useState("");
    const [file, setFile] = useState([]);
    const [value, setValue] = useState(1);
    const [isDisable, setIsDisable] = useState(false);
    const [progress, setProgress] = useState({
        current: 0,
        total: 10,
        test: 0,
    });
    const [article, setArticle] = useState("");
    const [isBulk, setIsBulk] = useState(false);
    const [bulkArticles, setBulkArticles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState("");
    // const [rate, setRate] = useState(1);
    // const [rateType, setRateType] = useState({ value: "day", label: "day" });

    const [checks, setChecks] = useState({
        imageCheck: false,
        videoCheck: false,
        // wordpressCheck: false,
    });
    const [imageChecks, setImageChecks] = useState({
        topCheck: false,
        centerCheck: false,
        bottomCheck: false,
    });
    // const [wordpress, setWordpress] = useState({
    //     url: "",
    //     username: "",
    //     password: "",
    //     category: "",
    // });

    const handleImgCheck = () => {
        setChecks({ ...checks, imageCheck: !checks.imageCheck });
    };
    const handleVidCheck = () => {
        setChecks({ ...checks, videoCheck: !checks.videoCheck });
    };
    // const handleWordpressCheck = () => {
    //     setChecks({ ...checks, wordpressCheck: !checks.wordpressCheck });
    // };
    // const handleWordpressUrl = (e) => {
    //     setWordpress({ ...wordpress, url: e.target.value });
    // };
    // const handleWordpressUsername = (e) => {
    //     setWordpress({ ...wordpress, username: e.target.value });
    // };
    // const handleWordpressPassword = (e) => {
    //     setWordpress({ ...wordpress, password: e.target.value });
    // };
    // const handleWordpressCategory = (e) => {
    //     setWordpress({ ...wordpress, category: e.target.value });
    // };

    const handleTopImgCheck = () => {
        setImageChecks({ ...imageChecks, topCheck: !imageChecks.topCheck });
    };
    const handleCenterCheck = () => {
        setImageChecks({
            ...imageChecks,
            centerCheck: !imageChecks.centerCheck,
        });
    };
    const handleBottomCheck = () => {
        setImageChecks({
            ...imageChecks,
            bottomCheck: !imageChecks.bottomCheck,
        });
    };

    const handleArticleGeneration = (e) => {
        e.preventDefault();
        if (keyword.length > 0) {
            setIsDisable(true);
            let tone = "";
            if (selectedToneOption.length === undefined) {
                tone = selectedToneOption.value;
            } else {
                for (let i = 0; i < selectedToneOption.length; ++i) {
                    tone += selectedToneOption[i].value;
                    tone += ", ";
                }
                tone = tone.slice(0, -2);
            }
            let brandStyle = "";
            if (selectedToneOption.length === undefined) {
                brandStyle = selectedBrandStyleOption.value;
            } else {
                brandStyle = selectedBrandStyleOption.value;
            }
            backend
                .GenerateArticle(
                    keyword,
                    selectedWordsOption.value,
                    selectedModelOption.value,
                    tone,
                    brandStyle,
                    // checks.wordpressCheck,
                    // wordpress.url,
                    // wordpress.username,
                    // wordpress.password,
                    // wordpress.category,
                    imageChecks.topCheck,
                    imageChecks.centerCheck,
                    imageChecks.bottomCheck,
                    checks.videoCheck,
                    // selected,
                    selectedProject.value
                )
                .then((res) => {
                    if (res.status === 200) {
                        // const WEBSOCKET_URL = `ws://${"localhost:8000"}/ws/`;
                        const WEBSOCKET_URL = `ws://${window.location.host}/ws/`;
                        //const WEBSOCKET_URL = `ws://${window.location.host}/ws/`;
                        //             const WEBSOCKET_URL = `ws://${"localhost:8000"}/ws/`;
                        //const WEBSOCKET_URL = `ws://${window.location.host}/ws/`;
                        const socket = new WebSocket(
                            WEBSOCKET_URL + `task/progress/${res.data.task_id}/`
                        );
                        //                        console.log(socket)

                        setProgress(1);
                        socket.onmessage = (event) => {
                            const parsedEvent = JSON.parse(event.data);
                            console.log(parsedEvent);
                            setProgress(Math.floor(parsedEvent.progress * 100));
                            if (parsedEvent.progress === 1) {
                                console.log(res.data);
                                backend
                                    .GetArticle(res.data.article_id)
                                    .then((res) => {
                                        setArticle(res.data[0].article);
                                        setIsDisable(false);
                                    });
                            }
                        };

                        socket.onerror = (err) => {
                            console.log(err);
                        };
                        socket.onclose = (event) => {
                            console.log(event);
                        };
                        socket.onopen = (event) => {
                            console.log(event);
                        };
                    }
                });
        }
    };

    const handleBulkGeneration = (e) => {
        e.preventDefault();
        // console.log(file.size);
        if (file.size > 0) {
            setIsDisable(true);
            let tone = "";
            for (let i = 0; i < selectedToneOption.length; ++i) {
                tone += selectedToneOption[i].value;
                tone += ", ";
            }
            tone = tone.slice(0, -2);
            let brandStyle = "";
            if (selectedToneOption.length === undefined) {
                brandStyle = selectedBrandStyleOption.value;
            } else {
                brandStyle = selectedBrandStyleOption.value;
            }
            const formData = new FormData();
            formData.append("file", file);
            backend
                .GenerateBulkArticles(
                    formData,
                    selectedWordsOption.value,
                    selectedModelOption.value,
                    tone,
                    brandStyle,
                    imageChecks.topCheck,
                    imageChecks.centerCheck,
                    imageChecks.bottomCheck,
                    checks.videoCheck,
                    selectedProject.value,
                    selectedProject.project_id
                )
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        toaster.push(successMessage, {
                            placement: "topEnd",
                        });
                        setIsDisable(false);
                        // if (checks.wordpressCheck === false) {
                        //     const WEBSOCKET_URL = `ws://${"localhost:8000"}/ws/`;
                        //     //const WEBSOCKET_URL = `ws://${window.location.host}/ws/`;
                        //     const socket = new WebSocket(
                        //         WEBSOCKET_URL +
                        //             `task/progress/${res.data.task_id}/`
                        //     );
                        //     setProgress(1);
                        //     socket.onmessage = (event) => {
                        //         const parsedEvent = JSON.parse(event.data);
                        //         console.log(parsedEvent);
                        //         setProgress(
                        //             Math.floor(parsedEvent.progress * 100)
                        //         );
                        //         if (parsedEvent.progress === 1) {
                        //             backend
                        //                 .GetBulkArticle(res.data.article_id)
                        //                 .then((res) => {
                        //                     setBulkArticles(res.data.bulk);
                        //                     console.log(res.data.bulk);
                        //                     setIsDisable(false);
                        //                 });
                        //         }
                        //     };
                        //     socket.onerror = (err) => {
                        //         console.log(err);
                        //     };
                        //     socket.onclose = (event) => {
                        //         console.log(event);
                        //     };
                        //     socket.onopen = (event) => {
                        //         console.log(event);
                        //     };
                        // } else {
                        //     setIsDisable(false);
                        // }
                    }
                });
        }
    };

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const options = {
        replace: ({ attribs, children }) => {
            if (!attribs) {
                return;
            }

            if (attribs.id === "title") {
                return (
                    <strong style={{ fontSize: 18 }}>
                        {domToReact(children, options)}
                    </strong>
                );
            }

            if (attribs.id === "subheading") {
                return (
                    <strong style={{ fontSize: 16 }}>
                        {domToReact(children, options)}
                    </strong>
                );
            }

            if (attribs.id === "para") {
                return (
                    <p style={{ fontSize: 14 }}>
                        {domToReact(children, options)}
                    </p>
                );
            }
        },
    };

    const re = new RegExp("<h1(.*)>(.*?)</h1>");

    const listItems = bulkArticles.map((d) => (
        <li
            key={d.article_id}
            onClick={() => {
                setModalData(d.article);
                openModal();
            }}
            style={{
                textDecoration: "underline",
                color: "#34a49c",
                cursor: "pointer",
            }}
        >
            {d.article.replace(/\n|\r/g, "").match(re) !== null
                ? d.article.replace(/\n|\r/g, "").match(re)[2]
                : ""}
        </li>
    ));

    const successMessage = (
        <Message showIcon type="success" header="Success">
            Bulk Generation Started
        </Message>
    );

    if (!user) {
        return <p>Loading...</p>;
    }

    return (
        <div className="app-bg">
            <BackgroundIcons />
            <div className="text-center">
                {bulkArticles.length > 0 ? (
                    <div className="container art-in-pad-top">
                        <div className="input-box  mx-auto">
                            <div className="d-flex pt-4">
                                <h5 className="ms-4 me-auto art-in-marg-10">
                                    Generated Articles
                                </h5>
                            </div>
                            <div className="mx-4 art-in-horizontal-line"></div>
                            <div className="text-start mx-4 pb-3">
                                {listItems}
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <Modal
                    show={showModal}
                    handleClose={closeModal}
                    article={modalData}
                    options={options}
                ></Modal>
                {article.length > 0 ? (
                    <div className="container art-in-pad-top">
                        <div className="input-box  mx-auto">
                            <div className="d-flex pt-4">
                                <h5 className="ms-4 me-auto art-in-marg-10">
                                    Generated Article
                                </h5>
                            </div>
                            <div className="mx-4 art-in-horizontal-line"></div>
                            <div className="text-start mx-4">
                                {parse(article, options)}
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}

                {progress > 0 && progress < 100 ? (
                    <div className="container art-in-pad-top">
                        <div className="input-box  mx-auto">
                            <div className="d-flex pt-4">
                                <h5 className="ms-4 me-auto art-in-marg-10">
                                    Processing your Query
                                </h5>
                            </div>
                            <div className="mx-4 art-in-horizontal-line mb-3"></div>
                            <div className="container mb-4">
                                <ProgressBar
                                    completed={progress}
                                    bgColor="#40ABA7"
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}

                <ArticleInput
                    selectedOption={selectedModelOption}
                    setSelectedOption={setSelectedModelOption}
                    keyword={keyword}
                    setKeyword={setKeyword}
                    isBulk={isBulk}
                    setIsBulk={setIsBulk}
                    setFile={setFile}
                />
                <ArticleStructure
                    selectedOption={selectedWordsOption}
                    setSelectedOption={setSelectedWordsOption}
                    value={value}
                    setValue={setValue}
                    selectedToneOption={selectedToneOption}
                    setSelectedToneOption={setSelectedToneOption}
                    selected={selectedProject}
                    setSelected={setSelectedProject}
                    projectList={projectList}
                    selectedBrandStyleOption={selectedBrandStyleOption}
                    setSelectedBrandStyleOption={setSelectedBrandStyleOption}
                    // setProjectInfo={setProjectInfo}
                />
                {/* <SelectPicker
                    value={selected}
                    onChange={(e) => {
                        setSelected(e);
                        projectList.map((item) =>
                            item.project_id == e
                                ? setProjectInfo(
                                      item.project_name,
                                      item.project_id
                                  )
                                : null
                        );
                    }}
                    data={data}
                    placement="rightEnd"
                    style={{
                        width: 224,
                        marginTop: "30px",
                        paddingBottom: "0px",
                    }}
                /> */}
                <ArticleStyle
                    styles={{ paddingTop: "0px !important" }}
                    checks={checks}
                    handleImgCheck={handleImgCheck}
                    handleVidCheck={handleVidCheck}
                    // handleWordpressCheck={handleWordpressCheck}
                    // wordpress={wordpress}
                    // setWpUrl={handleWordpressUrl}
                    // setWpUsername={handleWordpressUsername}
                    // setWpPassword={handleWordpressPassword}
                    // setWpCategory={handleWordpressCategory}
                    // isBulk={isBulk}
                    // rateValue={rate}
                    // setRateValue={setRate}
                    // rateType={rateType}
                    // setRateType={setRateType}
                    imageChecks={imageChecks}
                    handleTopImgCheck={handleTopImgCheck}
                    handleCenterImgCheck={handleCenterCheck}
                    handleBottomImgCheck={handleBottomCheck}
                />
                <button
                    className="article-generate-btn mb-5"
                    onClick={(e) => {
                        isBulk
                            ? handleBulkGeneration(e)
                            : handleArticleGeneration(e);
                    }}
                    disabled={isDisable}
                >
                    Generate
                </button>
            </div>
        </div>
    );
};

export default Home;
