import "./Modal.css";
import parse from "html-react-parser";

const NewProjectModal = ({ handleClose, show, article, options }) => {
    const showHideClassName = show
        ? "modal-display-block"
        : "modal-display-none";

    const callClose = () => {
        handleClose();
    };

    return (
        <div className={showHideClassName}>
            <section className="modal-main">
                <div className="py-4 px-5 text-start">
                    {parse(article, options)}
                </div>
                <div className="d-flex justify-content-center">
                    <button
                        type="button"
                        className="article-generate-btn mb-5"
                        onClick={callClose}
                    >
                        Close
                    </button>
                </div>
            </section>
        </div>
    );
};

export default NewProjectModal;
