import "./BackgroundIcons.css";

const BackgroundIcons = () => {
    return (
        <div className="position-relative">
            <div className="position-absolute top-0 end-0 ">
                <img src="images/dot-seamless-pattern.svg" width={100} />
            </div>
            <div className="background-icons-bottom">
                <img src="images/dot-seamless-pattern.svg" width={100} />
            </div>
        </div>
    );
};

export default BackgroundIcons;
