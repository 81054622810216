import React, { useState, useEffect } from "react";
import { useToaster, Message, Button } from "rsuite";
import { SelectPicker } from "rsuite";
import Api from "../Backend/Backend";

const DeleteProject = () => {
  const toaster = useToaster();
  const [projectName, setProjectName] = useState("");
  const [selected, setSelected] = useState(null);
  const [userId, setUserId] = useState(sessionStorage.getItem("username"));
  const [projectList, setProjectList] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const backend = new Api();

  useEffect(() => {
    backend.listProject(sessionStorage.getItem("username")).then((response) => {
      setProjectList(response.data);
    });
  }, [deleted]);

  const data = projectList.map((item) => ({
    label: item.project_name,
    value: item.project_id,
  }));

  const successMessage = (
    <Message showIcon type="success" header="Success">
      Project has been deleted!
    </Message>
  );
  const failureMessage = (
    <Message showIcon type="error" header="Error">
      Cannot delete project!
    </Message>
  );

  const handleDelete = () => {
    backend
      .deleteProject(userId, projectName)
      .then((res) => {
        if (res.status === 200) {
          toaster.push(successMessage, {
            placement: "topEnd",
          });
        }
        setDeleted(!deleted);
      })
      .catch(() => {
        toaster.push(failureMessage, {
          placement: "topEnd",
        });
      });
  };

  return (
    <div className="container text-center mt-2">
      <h3>Delete a Project</h3>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <SelectPicker
          value={selected}
          onChange={(e) => {
            setSelected(e);
            projectList.map((item) =>
              item.project_id == e ? setProjectName(item.project_name) : null
            );
          }}
          data={data}
          placement="rightEnd"
          style={{ width: 224, marginTop: "30px", paddingBottom: "0px" }}
        />
        {projectName ? (
          <>
            <Button
              onClick={() => handleDelete()}
              style={{
                backgroundColor: "#3498ff",
                color: "white",
                marginTop: "20px",
              }}
            >
              Delete
            </Button>
          </>
        ) : (
          <>
            <Button
              disabled
              style={{
                color: "gray",
                marginTop: "20px",
              }}
            >
              Delete
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default DeleteProject;
