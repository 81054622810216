import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useState } from "react";
import Home from "./Pages/Home";
import { Navigate } from "react-router-dom";
import Login from "./Pages/Login";
import SidebarLayout from "./Components/SidebarLayout";
import Articles from "./Pages/Articles";
import WordpressSettings from "./Pages/WordpressSettings";

function Urls(props) {
    // const [token, setToken] = useState();

    // if (!token) {
    //     return <Login setToken={setToken} />;
    // }

    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Login />}></Route>
                <Route
                    exact
                    path="/dashboard"
                    element={<SidebarLayout children={<Home />} />}
                ></Route>
                <Route
                    exact
                    path="/articles"
                    element={<SidebarLayout children={<Articles />} />}
                ></Route>
                <Route
                    exact
                    path="/wordpress-settings"
                    element={<SidebarLayout children={<WordpressSettings />} />}
                ></Route>
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Urls;
