import { useEffect, useState } from "react";
import Switch from "react-switch";
import Select from "react-select";

const ArticleStyle = ({
    checks,
    handleImgCheck,
    handleVidCheck,
    // handleWordpressCheck,
    // wordpress,
    // setWpUrl,
    // setWpUsername,
    // setWpPassword,
    // setWpCategory,
    // isBulk,
    // rateValue,
    // setRateValue,
    // rateType,
    // setRateType,
    imageChecks,
    handleTopImgCheck,
    handleCenterImgCheck,
    handleBottomImgCheck,
}) => {
    // const min = 1;
    // const [max, setMax] = useState(21);

    // useEffect(() => {
    //     console.log(rateType.value);
    //     if (rateType.value === "day") {
    //         setMax(21);
    //         setRateValue(1);
    //     } else {
    //         setMax(147);
    //     }
    // }, [rateType]);

    // const handleRateChange = (event) => {
    //     const value = Math.max(min, Math.min(max, Number(event.target.value)));
    //     setRateValue(value);
    // };
    // const options = [
    //     { value: "day", label: "day" },
    //     { value: "week", label: "week" },
    // ];
    // const colourStyles = {
    //     control: (styles) => ({
    //         ...styles,
    //         backgroundColor: "#F8F4F4",
    //         width: "150px",
    //     }),
    //     menu: (provided) => ({ ...provided, zIndex: 9999 }),
    // };
    return (
        <div className="container art-in-pad-top">
            <div className="input-box  mx-auto">
                <div className="d-flex pt-4">
                    <h5 className="ms-4 me-auto art-in-marg-10">
                        Customize your Article's Style
                    </h5>
                </div>
                <div className="mx-4 art-in-horizontal-line"></div>
                <div className="row py-3">
                    <div className="col">
                        <div className="d-flex justify-content-end">
                            <label>
                                <strong>Add an Image</strong>
                            </label>
                        </div>
                        {checks.imageCheck ? (
                            <div>
                                <div className="d-flex justify-content-end mt-4">
                                    <label>
                                        <strong>Top Image</strong>
                                    </label>
                                </div>
                                <div className="d-flex justify-content-end mt-4">
                                    <label>
                                        <strong>Center Image</strong>
                                    </label>
                                </div>
                                <div className="d-flex justify-content-end mt-4">
                                    <label>
                                        <strong>Bottom Image</strong>
                                    </label>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="d-flex justify-content-end mt-4">
                            <label>
                                <strong>Add a Video</strong>
                            </label>
                        </div>
                        {/* <div className="d-flex justify-content-end mt-4">
                            <label>
                                <strong>Post to your WordPress Blog</strong>
                            </label>
                        </div> */}
                        {/* {checks.wordpressCheck ? (
                            <div>
                                <div className="d-flex justify-content-end mt-4">
                                    <label>
                                        <strong>Wordpress URL</strong>
                                    </label>
                                </div>
                                <div className="d-flex justify-content-end mt-4">
                                    <label>
                                        <strong>Wordpress Username</strong>
                                    </label>
                                </div>
                                <div className="d-flex justify-content-end mt-4">
                                    <label>
                                        <strong>Wordpress Password</strong>
                                    </label>
                                </div>
                                <div className="d-flex justify-content-end mt-4">
                                    <label>
                                        <strong>Category</strong>
                                    </label>
                                </div>
                                {isBulk ? (
                                    <div className="d-flex justify-content-end mt-4">
                                        <label>
                                            <strong>
                                                Rate of Article Posting
                                            </strong>
                                        </label>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        ) : (
                            ""
                        )} */}
                    </div>
                    <div className="col">
                        <div className="d-flex">
                            <Switch
                                onChange={handleImgCheck}
                                checked={checks.imageCheck}
                                onColor="#40ABA7"
                                offColor="#888888"
                                onHandleColor="#ffffff"
                                offHandleColor="#ffffff"
                            />
                        </div>
                        {checks.imageCheck ? (
                            <div>
                                <div className="d-flex mt-3">
                                    <Switch
                                        onChange={handleTopImgCheck}
                                        checked={imageChecks.topCheck}
                                        onColor="#40ABA7"
                                        offColor="#888888"
                                        onHandleColor="#ffffff"
                                        offHandleColor="#ffffff"
                                    />
                                </div>
                                <div className="d-flex mt-3">
                                    <Switch
                                        onChange={handleCenterImgCheck}
                                        checked={imageChecks.centerCheck}
                                        onColor="#40ABA7"
                                        offColor="#888888"
                                        onHandleColor="#ffffff"
                                        offHandleColor="#ffffff"
                                    />
                                </div>
                                <div className="d-flex mt-3">
                                    <Switch
                                        onChange={handleBottomImgCheck}
                                        checked={imageChecks.bottomCheck}
                                        onColor="#40ABA7"
                                        offColor="#888888"
                                        onHandleColor="#ffffff"
                                        offHandleColor="#ffffff"
                                    />
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="d-flex mt-3">
                            <Switch
                                onChange={handleVidCheck}
                                checked={checks.videoCheck}
                                onColor="#40ABA7"
                                offColor="#888888"
                                onHandleColor="#ffffff"
                                offHandleColor="#ffffff"
                            />
                        </div>
                        {/* <div className="d-flex mt-3 pt-1">
                            <Switch
                                onChange={handleWordpressCheck}
                                checked={checks.wordpressCheck}
                                onColor="#40ABA7"
                                offColor="#888888"
                                onHandleColor="#ffffff"
                                offHandleColor="#ffffff"
                            />
                        </div>
                        {checks.wordpressCheck ? (
                            <div>
                                <div className="d-flex mt-3 pt-1">
                                    <input
                                        placeholder="Wordpress URL"
                                        type="text"
                                        className="art-in-input ps-3"
                                        value={wordpress.wpUrl}
                                        onChange={(e) => setWpUrl(e)}
                                    />
                                </div>
                                <div className="d-flex mt-3 pt-1">
                                    <input
                                        placeholder="Wordpress Username"
                                        type="text"
                                        className="art-in-input ps-3"
                                        value={wordpress.wpUsername}
                                        onChange={(e) => setWpUsername(e)}
                                    />
                                </div>
                                <div className="d-flex mt-3 pt-1">
                                    <input
                                        placeholder="Wordpress Password"
                                        type="password"
                                        className=" ps-3"
                                        value={wordpress.wpPassword}
                                        onChange={(e) => setWpPassword(e)}
                                    />
                                </div>
                                <div className="d-flex mt-3 pt-1">
                                    <input
                                        placeholder="Wordpress Category"
                                        type="text"
                                        className="art-in-input ps-3"
                                        value={wordpress.category}
                                        onChange={(e) => setWpCategory(e)}
                                    />
                                </div>
                                {isBulk ? (
                                    <div className="d-flex">
                                        <div className="mt-3 pt-1 me-3">
                                            <input
                                                type="number"
                                                value={rateValue}
                                                onChange={handleRateChange}
                                                style={{ width: 100 }}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <Select
                                                styles={colourStyles}
                                                defaultValue={rateType}
                                                onChange={setRateType}
                                                options={options}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        ) : (
                            ""
                        )} */}
                    </div>
                    {/* {isBulk && checks.wordpressCheck ? (
                        <p className="mt-3">
                            You can have a maximum of 21 posts per day or 147
                            posts per week.
                        </p>
                    ) : (
                        ""
                    )} */}
                </div>
            </div>
        </div>
    );
};

export default ArticleStyle;
