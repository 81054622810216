const firebaseConfig = {
    apiKey: "AIzaSyCXDCvXALM8Tc18PmZOFG57E0j1uSUunDc",
    authDomain: "articlegenerator-3793d.firebaseapp.com",
    projectId: "articlegenerator-3793d",
    storageBucket: "articlegenerator-3793d.appspot.com",
    messagingSenderId: "364406279020",
    appId: "1:364406279020:web:7f3fd7c3543fa118af8149",
    measurementId: "G-RG020NMRYX",
};

export default firebaseConfig;
