import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Login.css";
// import Api from "../Backend/Backend";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useNavigate } from "react-router-dom";

export default function Login() {
    // const [username, setUserName] = useState();
    // const [password, setPassword] = useState();

    // const backend = new Api();

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     backend.UserLogin(username, password).then((res) => {
    //         if (res.status === 200) {
    //             sessionStorage.setItem("username", username);
    //             setToken(true);
    //         }
    //     });
    // };

    const [error, setError] = useState(null);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        firebase
            .auth()
            .signInWithEmailAndPassword(userName, password)
            .then(() => {
                sessionStorage.setItem(
                    "username",
                    firebase.auth().currentUser.uid
                );
                navigate("/dashboard");
            })
            .catch((error) => {
                // An error happened.
                setError(error.message);
            });
    };

    return (
        <div className="Auth-form-container">
            <form className="Auth-form" onSubmit={handleSubmit}>
                <div className="Auth-form-content">
                    <h3 className="Auth-form-title">Sign In</h3>
                    <div className="form-group mt-3">
                        <label>Email</label>
                        <input
                            type="email"
                            className="form-control mt-1"
                            placeholder="Enter Email"
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label>Password</label>
                        <input
                            type="password"
                            className="form-control mt-1"
                            placeholder="Enter password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="d-grid gap-2 mt-3">
                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </div>
                    {error && error}
                </div>
            </form>
        </div>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired,
};
