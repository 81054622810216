import axios from "axios";

import { ApiUrls } from "./ApiConfig.js";

export default class Api {
    GenerateArticle(
        keyword,
        n_words,
        model,
        tone,
        brandStyle,
        // wordpressCheck,
        // url,
        // username,
        // password,
        // category,
        topImg,
        centerImg,
        bottomImg,
        videoCheck,
        project_id,
        project_name
    ) {
        return new Promise(function (resolve, reject) {
            // if (wordpressCheck === true) {
            //     if (category.length > 0) {
            //         const data = {
            //             keyword: keyword,
            //             words: n_words,
            //             model: model,
            //             tone: tone,
            //             site_link: url,
            //             username: username,
            //             password: password,
            //             post_status: "publish",
            //             category: category,
            //             top: topImg,
            //             center: centerImg,
            //             bottom: bottomImg,
            //             video: videoCheck,
            //         };
            //         console.log(data);
            //         axios
            //             .post(ApiUrls.GenerateArticle, data)
            //             .then(function (response) {
            //                 resolve(response);
            //             })
            //             .catch((error) => {
            //                 reject(error);
            //             });
            //     } else {
            //         const data = {
            //             keyword: keyword,
            //             words: n_words,
            //             model: model,
            //             tone: tone,
            //             site_link: url,
            //             username: username,
            //             password: password,
            //             post_status: "publish",
            //             top: topImg,
            //             center: centerImg,
            //             bottom: bottomImg,
            //             video: videoCheck,
            //         };
            //         console.log(data);
            //         axios
            //             .post(ApiUrls.GenerateArticle, data)
            //             .then(function (response) {
            //                 resolve(response);
            //             })
            //             .catch((error) => {
            //                 reject(error);
            //             });
            //     }
            // } else {
            const data = {
                user_id: sessionStorage.getItem("username"),
                keyword: keyword,
                words: n_words,
                model: model,
                tone: tone,
                brand_style: brandStyle,
                top: topImg,
                center: centerImg,
                bottom: bottomImg,
                video: videoCheck,
                project_id: project_id,
                project_name: project_name,
            };
            console.log(data);
            axios
                .post(ApiUrls.GenerateArticle, data)
                .then(function (response) {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    GenerateBulkArticles(
        formdata,
        n_words,
        model,
        tone,
        brandStyle,
        topImg,
        centerImg,
        bottomImg,
        videoCheck,
        projectName,
        projectId
    ) {
        return new Promise(function (resolve, reject) {
            const data = {
                user_id: sessionStorage.getItem("username"),
                words: n_words,
                model: model,
                tone: tone,
                brand_style: brandStyle,
                top: topImg,
                center: centerImg,
                bottom: bottomImg,
                video: videoCheck,
                project: projectName,
                project_id: projectId,
            };

            const json = JSON.stringify(data);
            // const blob = new Blob([json], {
            //     type: "application/json",
            // });
            formdata.append("data", json);
            // console.log(blob);
            // console.log(data);
            axios
                .post(ApiUrls.GenerateBulkArticles, formdata)
                .then(function (response) {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    GetArticle(id) {
        return new Promise(function (resolve, reject) {
            const data = {
                user_id: sessionStorage.getItem("username"),
                article_id: id,
            };
            axios
                .post(ApiUrls.GetArticle, data)
                .then(function (response) {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    GetBulkArticle(id) {
        return new Promise(function (resolve, reject) {
            const data = {
                task_id: id,
            };
            axios
                .post(ApiUrls.GetBulkArticle, data)
                .then(function (response) {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    GetProgress(id) {
        return new Promise(function (resolve, reject) {
            const data = {
                task_id: id,
            };
            axios
                .post(ApiUrls.GetProgress, data)
                .then(function (response) {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    UserLogin(username, password) {
        return new Promise(function (resolve, reject) {
            const data = {
                username: username,
                password: password,
            };
            // console.log(data);
            axios
                .post(ApiUrls.UserLogin, data)
                .then(function (response) {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getArticles(username, page_number) {
        return new Promise(function (resolve, reject) {
            const data = {
                user_id: username,
                page: page_number,
            };
            // console.log(data);
            axios
                .post(ApiUrls.GetArticles, data)
                .then(function (response) {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    addWordpress(requiredData) {
        return new Promise(function (resolve, reject) {
            const data = requiredData;
            console.log(data);
            axios
                .post(ApiUrls.AddWordpress, data)
                .then(function (response) {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getWordpressAccounts(username) {
        return new Promise(function (resolve, reject) {
            const data = {
                user_id: username,
            };
            axios
                .post(ApiUrls.GetWordpressAccounts, data)
                .then(function (response) {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    editArticle(article_id, article) {
        return new Promise(function (resolve, reject) {
            const data = {
                article_id: article_id,
                article: article,
            };
            axios
                .put(ApiUrls.EditArticle, data)
                .then(function (response) {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    publishSoloArticle(account_id, article_id, category) {
        return new Promise(function (resolve, reject) {
            const data = {
                account_id: account_id,
                articles: [article_id],
                category: category,
            };
            axios
                .post(ApiUrls.PublishSoloArticle, data)
                .then(function (response) {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    publishBulkArticles(
        account_id,
        article_ids,
        category,
        schedule_type,
        rate
    ) {
        return new Promise(function (resolve, reject) {
            const data = {
                account_id: account_id,
                articles: article_ids,
                category: category,
                schedule_type: schedule_type,
                post_frequency: parseInt(rate),
            };
            axios
                .post(ApiUrls.PublishSoloArticle, data)
                .then(function (response) {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    createProject(project) {
        return new Promise(function (resolve, reject) {
            const data = {
                project: project.project,
                user_id: project.user_id,
            };
            axios
                .post(ApiUrls.CreateProject, data)
                .then(function (response) {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    listProject(user_id) {
        return new Promise(function (resolve, reject) {
            axios
                .post(ApiUrls.ListProject, { user_id: user_id })
                .then(function (response) {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    deleteArticle(article_ids) {
        return new Promise(function (resolve, reject) {
            axios
                .post(ApiUrls.DeleteArticle, { article_ids: article_ids })
                .then(function (response) {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    deleteProject(userId, projectName) {
        return new Promise(function (resolve, reject) {
            axios
                .post(ApiUrls.DeleteProject, {
                    user_id: userId,
                    project: projectName,
                })
                .then(function (response) {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    deleteAccount(account_id) {
        return new Promise(function (resolve, reject) {
            axios
                .post(ApiUrls.DeleteAccount, { account_id: account_id })
                .then(function (response) {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    editAccount(formdata) {
        return new Promise(function (resolve, reject) {
            axios
                .put(ApiUrls.EditAccount, formdata)
                .then(function (response) {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    downloadArticles(article_ids) {
        return new Promise(function (resolve, reject) {
            axios
                .post(ApiUrls.DownloadArticles, { article_ids: article_ids })
                .then(function (response) {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }
}
