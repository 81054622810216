import { useState, useEffect } from "react";
import {
    Form,
    ButtonToolbar,
    Button,
    Schema,
    Message,
    useToaster,
    Loader,
    FlexboxGrid,
    Divider,
} from "rsuite";
import Api from "../Backend/Backend";
import AddProjectForm from "../Components/AddProjectForm";
import DeleteProject from "../Components/DeleteProject";
import RemoveWordpress from "../Components/RemoveWordpress";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useNavigate } from "react-router-dom";

const WordpressSettings = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setUser(user);
            } else {
                navigate("/");
            }
        });
        return () => unsubscribe();
    }, [navigate]);
    const backend = new Api();
    const toaster = useToaster();
    const initFormData = {
        website_name: "",
        url: "",
        username: "",
        password: "",
        user_id: sessionStorage.getItem("username"),
    };
    const model = Schema.Model({
        name: Schema.Types.StringType().isRequired("Unique name is required."),
        username: Schema.Types.StringType().isRequired("Username is required"),
        url: Schema.Types.StringType().isURL("Please enter a valid URL."),
        password: Schema.Types.StringType().isRequired("Password is required."),
    });
    const [formdata, setFormdata] = useState(initFormData);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = () => {
        setIsLoading(true);
        backend
            .addWordpress(formdata)
            .then((res) => {
                if (res.status === 200) {
                    toaster.push(successMessage, {
                        placement: "topEnd",
                    });
                    setIsLoading(false);
                }
            })
            .catch(() => {
                toaster.push(failureMessage, {
                    placement: "topEnd",
                });
                setIsLoading(false);
            });
    };

    const successMessage = (
        <Message showIcon type="success" header="Success">
            Wordpress verified and added successfully
        </Message>
    );

    const failureMessage = (
        <Message showIcon type="error" header="Error">
            Cannot authenticate on wordpress
        </Message>
    );

    if (!user) {
        return <p>Loading...</p>;
    }
    return (
        <div className="show-grid">
            <FlexboxGrid justify="flex-start">
                <FlexboxGrid.Item colspan={8}>
                    <AddProjectForm />
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Divider style={{ width: "50%" }} />
                    </div>
                    <DeleteProject />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={8}>
                    <div
                        className="container text-center mt-5"
                        styles={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItem: "flex-start",
                        }}
                    >
                        <h3>Add a new Wordpress Site</h3>
                        <Form
                            formValue={formdata}
                            onChange={(formdata) => setFormdata(formdata)}
                            model={model}
                        >
                            <Form.Group controlId="website_name">
                                <Form.ControlLabel>Name</Form.ControlLabel>
                                <Form.Control name="website_name" />
                                <Form.HelpText tooltip>
                                    Name is required
                                </Form.HelpText>
                            </Form.Group>
                            <Form.Group controlId="url">
                                <Form.ControlLabel>URL</Form.ControlLabel>
                                <Form.Control name="url" />
                                <Form.HelpText tooltip>
                                    URL is required
                                </Form.HelpText>
                            </Form.Group>
                            <Form.Group controlId="username">
                                <Form.ControlLabel>Username</Form.ControlLabel>
                                <Form.Control name="username" />
                                <Form.HelpText tooltip>
                                    Username is required
                                </Form.HelpText>
                            </Form.Group>
                            <Form.Group controlId="password">
                                <Form.ControlLabel>Password</Form.ControlLabel>
                                <Form.Control
                                    name="password"
                                    type="password"
                                    autoComplete="off"
                                />
                                <Form.HelpText tooltip>
                                    Password is required
                                </Form.HelpText>
                            </Form.Group>
                            {isLoading ? (
                                <Loader content="Verifying..." vertical />
                            ) : (
                                <Form.Group>
                                    <ButtonToolbar>
                                        <Button
                                            appearance="primary"
                                            onClick={() => handleSubmit()}
                                        >
                                            Submit
                                        </Button>
                                        <Button
                                            appearance="default"
                                            onClick={() =>
                                                setFormdata(initFormData)
                                            }
                                        >
                                            Clear
                                        </Button>
                                    </ButtonToolbar>
                                </Form.Group>
                            )}
                        </Form>
                    </div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={8}>
                    <RemoveWordpress />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </div>
    );
};

export default WordpressSettings;
