import "./ArticleInput.css";
import { useState } from "react";
import Select from "react-select";

const ArticleInput = ({
    selectedOption,
    setSelectedOption,
    keyword,
    setKeyword,
    isBulk,
    setIsBulk,
    setFile,
}) => {
    const options = [
        { value: 0, label: "Davinci" },
        { value: 1, label: "Curie" },
        { value: 3, label: "Ada" },
        { value: 2, label: "Baggage" },
    ];

    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#F8F4F4",
            width: "150px",
        }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
    };

    const handleFilesChosen = (event) => {
        setFile(event.target.files[0]);
    };

    return (
        <div>
            {isBulk === true ? (
                <div className="container art-in-pad-top">
                    <div className="input-box  mx-auto">
                        <div className="d-flex pt-4">
                            <h5 className="ms-4 me-auto art-in-marg-10">
                                Upload text file for Bulk Generation
                            </h5>
                            <p className="me-4">Choose a text file</p>
                        </div>
                        <div className="mx-4 art-in-horizontal-line"></div>
                        <div className="d-flex justify-content-evenly py-3">
                            <label className="mt-1">
                                <strong>Upload a file</strong>
                            </label>
                            <input
                                type="file"
                                multiple={false}
                                accept=".txt"
                                className="ps-3 mt-1"
                                onChange={handleFilesChosen}
                            />
                            <Select
                                styles={colourStyles}
                                defaultValue={selectedOption}
                                onChange={setSelectedOption}
                                options={options}
                            />
                        </div>
                        <p
                            className="d-flex ms-5"
                            style={{ color: "#40ABA7", cursor: "pointer" }}
                            onClick={() => setIsBulk(!isBulk)}
                        >
                            <u>Switch to Solo Article Generator</u>
                        </p>
                    </div>
                </div>
            ) : (
                <div className="container art-in-pad-top">
                    <div className="input-box  mx-auto">
                        <div className="d-flex pt-4">
                            <h5 className="ms-4 me-auto art-in-marg-10">
                                Create a New Article
                            </h5>
                            <p className="me-4">
                                Choose a topic for your article
                            </p>
                        </div>
                        <div className="mx-4 art-in-horizontal-line"></div>
                        <div className="d-flex justify-content-evenly py-3">
                            <label className="mt-1">
                                <strong>Keywords</strong>
                            </label>
                            <input
                                placeholder="how to create SEO based strategy"
                                type="text"
                                className="art-in-input ps-3"
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                            />
                            <Select
                                styles={colourStyles}
                                defaultValue={selectedOption}
                                onChange={setSelectedOption}
                                options={options}
                            />
                        </div>
                        <p
                            className="d-flex ms-5"
                            style={{ color: "#40ABA7", cursor: "pointer" }}
                            onClick={() => setIsBulk(!isBulk)}
                        >
                            <u>Switch to Bulk Article Generator</u>
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ArticleInput;
