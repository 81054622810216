import React, { useState } from "react";
import {
  Form,
  ButtonToolbar,
  Button,
  Schema,
  useToaster,
  Message,
  Loader,
} from "rsuite";
import Api from "../Backend/Backend";

const AddProjectForm = () => {
  const toaster = useToaster();
  const [projectName, setProjectName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const backend = new Api();

  const initFormData = {
    project: "",
    user_id: sessionStorage.getItem("username"),
  };

  const model = Schema.Model({
    project: Schema.Types.StringType().isRequired(
      "Unique project name is required"
    ),
  });

  const [formdata, setFormdata] = useState(initFormData);

  const successMessage = (
    <Message showIcon type="success" header="Success">
      Project has been added
    </Message>
  );
  const failureMessage = (
    <Message showIcon type="error" header="Error">
      Cannot add project
    </Message>
  );

  const handleSubmit = () => {
    setIsLoading(true);
    backend
      .createProject(formdata)
      .then((res) => {
        if (res.status === 200) {
          toaster.push(successMessage, {
            placement: "topEnd",
          });
          setIsLoading(false);
        }
      })
      .catch(() => {
        toaster.push(failureMessage, {
          placement: "topEnd",
        });
        setIsLoading(false);
      });
  };
  

  return (
    <div className="container text-center mt-5">
      <h3>Add a new Project</h3>
      <Form
        formValue={formdata}
        onChange={(formdata) => setFormdata(formdata)}
        model={model}
      >
        <Form.Group controlId="name">
          <Form.ControlLabel>Project Name</Form.ControlLabel>
          <Form.Control name="project" />
          <Form.HelpText tooltip>Project name is required</Form.HelpText>
        </Form.Group>
        {isLoading ? (
          <Loader content="Verifying..." vertical />
        ) : (
          <Form.Group>
            <ButtonToolbar>
              <Button appearance="primary" onClick={() => handleSubmit()}>
                Submit
              </Button>
              <Button
                appearance="default"
                onClick={() => setFormdata(initFormData)}
              >
                Clear
              </Button>
            </ButtonToolbar>
          </Form.Group>
        )}
      </Form>
    </div>
  );
};

export default AddProjectForm;
