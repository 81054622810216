import React, { useState, useEffect } from "react";
import {
    Button,
    Container,
    Table,
    Checkbox,
    Pagination,
    Modal,
    Loader,
    Input,
    InputPicker,
    InputNumber,
} from "rsuite";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import "./Articles.css";
import Api from "../Backend/Backend";
import { SelectPicker } from "rsuite";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useNavigate } from "react-router-dom";

const { Column, HeaderCell, Cell } = Table;

const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
    <Cell {...props} style={{ padding: 0 }}>
        <div style={{ lineHeight: "46px" }}>
            <Checkbox
                value={rowData[dataKey]}
                inline
                onChange={onChange}
                checked={checkedKeys.some((item) => item === rowData[dataKey])}
            />
        </div>
    </Cell>
);

const Articles = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setUser(user);
            } else {
                navigate("/");
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    const backend = new Api();

    const [activePage, setActivePage] = useState(1);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => {
        setEditableArticle({});
        setOpenEdit(false);
    };
    const [openPublish, setOpenPublish] = useState(false);
    const [openBulkPublish, setOpenBulkPublish] = useState(false);
    const handleOpenPublish = () => setOpenPublish(true);
    const handleClosePublish = () => setOpenPublish(false);
    const handleOpenBulkPublish = () => setOpenBulkPublish(true);
    const handleCloseBulkPublish = () => setOpenBulkPublish(false);
    let checked = false;
    let indeterminate = false;
    const [editableArticle, setEditableArticle] = useState();
    const [articlesList, setArticlesList] = useState({ articles: [] });
    const [articlesFiltered, setArticlesFiltered] = useState({ articles: [] });
    const [wordpressAccounts, setWordpressAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState();
    const [wordpressCategory, setWordpressCategory] = useState("");
    const [selectedArticle, setSelectedArticle] = useState();
    const [deleteSelect, setDeleteSelect] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);
    const handleOpenDelete = (article_id) => {
        console.log(article_id);
        setDeleteSelect(article_id);
        setOpenDelete(!openDelete);
    };
    const [rate, setRate] = useState(3);
    const [rateType, setRateType] = useState("day");
    const rateTypeData = [
        { value: "day", label: "day" },
        { value: "week", label: "week" },
    ];
    const [max, setMax] = useState(99999);

    const [selected, setSelected] = React.useState(null);
    const [projectList, setProjectList] = useState([]);
    const [projectName, setProjectName] = useState("");
    const [filterOn, setFilterOn] = useState(false);
    const [deleteComplete, setDeleteComplete] = useState(false);

    useEffect(() => {
        const user = sessionStorage.getItem("username");
        backend.getArticles(user, parseInt(activePage)).then((res) => {
            if (res.status === 200) {
                setArticlesList(res.data);
            }
        });
    }, [deleteComplete]);

    useEffect(() => {
        const user = sessionStorage.getItem("username");
        backend.getWordpressAccounts(user).then((res) => {
            if (res.status === 200) {
                setWordpressAccounts(
                    res.data.map((obj) => ({
                        label: obj.website_name,
                        value: obj.account_id,
                    }))
                );
            }
        });
    }, []);

    useEffect(() => {
        backend
            .listProject(sessionStorage.getItem("username"))
            .then((response) => {
                setProjectList(response.data);
            });
    }, []);

    const selectListData = projectList.map((item) => ({
        label: item.project_name,
        value: item.project_id,
    }));

    if (checkedKeys.length === articlesList["articles"].length) {
        checked = true;
    } else if (checkedKeys.length === 0) {
        checked = false;
    } else if (
        checkedKeys.length > 0 &&
        checkedKeys.length < articlesList["articles"].length
    ) {
        indeterminate = true;
    }

    const handleCheckAll = (value, checked) => {
        const keys = checked
            ? articlesList["articles"].map((item) => item.article_id)
            : [];
        setCheckedKeys(keys);
    };
    const handleCheck = (value, checked) => {
        const keys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter((item) => item !== value);
        setCheckedKeys(keys);
    };

    const handleDelete = (deleteSelect) => {
        backend.deleteArticle(deleteSelect);
        setDeleteComplete(!deleteComplete);
        setOpenDelete(!openDelete);
        setDeleteComplete(!deleteComplete);
        setCheckedKeys([]);
    };

    const openEditModal = (rowData) => {
        backend.GetArticle(rowData.article_id).then((res) => {
            if (res.status === 200) {
                setEditableArticle({
                    id: rowData.article_id,
                    title: rowData.topic,
                    body: rowData.article,
                });
            }
        });

        handleOpenEdit();
    };

    const openPublishModal = (rowData) => {
        setSelectedArticle(rowData.article_id);
        handleOpenPublish();
    };

    const handleEditArticle = () => {
        handleCloseEdit();
        backend.editArticle(editableArticle.id, editableArticle.body);
    };

    const handleSoloPublish = () => {
        handleClosePublish();
        backend.publishSoloArticle(
            selectedAccount,
            selectedArticle,
            wordpressCategory
        );
    };

    const handleDownload = (articleArray) => {
        backend.downloadArticles(articleArray).then((res) => {
            console.log(res.data);
            const element = document.createElement("a");
            const file = new Blob([res.data.articles[0]], {
                type: "text/html",
            });
            element.href = URL.createObjectURL(file);
            element.download = `${res.data.topics[0]}.html`;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        });
    };

    const handleBulkDownload = (articlesArray) => {
        const zip = new JSZip();
        const folder = zip.folder("files");
        backend.downloadArticles(articlesArray).then((res) => {
            res.data.articles.forEach((item, index) => {
                const file = new Blob([res.data.articles[index]], {
                    type: "text/html",
                });
                folder.file(`${res.data.topics[index]}.html`, file);
            });
            zip.generateAsync({ type: "blob" }).then((blob) =>
                saveAs(blob, "Articles.zip")
            );
        });
    };

    // const saveZip = (filename, urls) => {
    //     urls.forEach((url) => {
    //         const blobPromise = fetch(url).then((r) => {
    //             if (r.status === 200) return r.blob();
    //             return Promise.reject(new Error(r.statusText));
    //         });
    //         const name = url.substring(url.lastIndexOf("/") + 1);
    //         folder.file(name, blobPromise);
    //     });

    //     zip.generateAsync({ type: "blob" }).then((blob) =>
    //         saveAs(blob, filename)
    //     );
    // };

    const handleBulkPublish = () => {
        // console.log(checkedKeys);
        // console.log(selectedAccount);
        // console.log(wordpressCategory);
        // console.log(rate);
        // console.log(rateType);
        handleCloseBulkPublish();
        backend.publishBulkArticles(
            selectedAccount,
            checkedKeys,
            wordpressCategory,
            rateType,
            rate
        );
    };

    const handlePageChange = (e) => {
        const user = sessionStorage.getItem("username");
        backend.getArticles(user, e).then((res) => {
            if (res.status === 200) {
                setArticlesList(res.data);
                setActivePage(e);
            }
        });
    };

    const filterArticles = (_id) => {
        let list = articlesList.articles.filter(
            (article) => article.project_id === _id
        );
        setArticlesFiltered({ articles: list });
        setFilterOn(true);
    };

    const handleRateTypeChange = (e) => {
        setRateType(e);
        setRate(1);
        // if (e === "day") {
        //     setMax(24);
        // } else {
        //     setMax(168);
        // }
    };

    if (!user) {
        return <p>Loading...</p>;
    }

    return (
        <div className="mt-4">
            <Container>
                <div className="d-flex justify-content-center">
                    <Table
                        height={window.innerHeight - 120}
                        data={
                            articlesFiltered.articles.length > 0 || filterOn
                                ? articlesFiltered["articles"]
                                : articlesList["articles"]
                        }
                        onRowClick={(rowData) => {
                            console.log(rowData);
                        }}
                        style={{ width: "80%" }}
                    >
                        <Column width={50} align="center">
                            <HeaderCell style={{ padding: 0 }}>
                                <div style={{ lineHeight: "40px" }}>
                                    <Checkbox
                                        inline
                                        checked={checked}
                                        indeterminate={indeterminate}
                                        onChange={handleCheckAll}
                                    />
                                </div>
                            </HeaderCell>
                            <CheckCell
                                dataKey="article_id"
                                checkedKeys={checkedKeys}
                                onChange={handleCheck}
                            />
                        </Column>
                        <Column width={60} align="center" fixed>
                            <HeaderCell>Id</HeaderCell>
                            <Cell dataKey="article_id" />
                        </Column>

                        <Column width={window.innerWidth / 3}>
                            <HeaderCell>Title</HeaderCell>
                            <Cell dataKey="topic" />
                        </Column>

                        <Column width={80} align="center">
                            <HeaderCell>Published</HeaderCell>
                            {/* <Cell dataKey="published" /> */}
                            <Cell>
                                {(rowData) =>
                                    rowData.post_status === true ? (
                                        <CheckRoundIcon />
                                    ) : (
                                        <CloseOutlineIcon />
                                    )
                                }
                            </Cell>
                        </Column>
                        <Column width={80} fixed="right">
                            <HeaderCell>...</HeaderCell>

                            <Cell>
                                {(rowData) => (
                                    <button
                                        onClick={() => openEditModal(rowData)}
                                        style={{
                                            backgroundColor: "#40aba7",
                                            color: "white",
                                        }}
                                    >
                                        Edit
                                    </button>
                                )}
                            </Cell>
                        </Column>
                        <Column width={80} fixed="right">
                            <HeaderCell>...</HeaderCell>

                            <Cell>
                                {(rowData) =>
                                    rowData.published === "true" ? (
                                        <button className="disabled">
                                            Publish
                                        </button>
                                    ) : (
                                        <button
                                            style={{
                                                backgroundColor: "#40aba7",
                                                color: "white",
                                            }}
                                            onClick={() =>
                                                openPublishModal(rowData)
                                            }
                                        >
                                            Publish
                                        </button>
                                    )
                                }
                            </Cell>
                        </Column>
                        <Column width={100} fixed="right">
                            <HeaderCell>...</HeaderCell>

                            <Cell>
                                {(rowData) => (
                                    <button
                                        onClick={() =>
                                            handleDownload([rowData.article_id])
                                        }
                                        style={{
                                            backgroundColor: "#40aba7",
                                            color: "white",
                                        }}
                                    >
                                        Download
                                    </button>
                                )}
                            </Cell>
                        </Column>
                        <Column width={80} fixed="right">
                            <HeaderCell>...</HeaderCell>

                            <Cell>
                                {(rowData) =>
                                    rowData.article_id ? (
                                        <button
                                            style={{
                                                backgroundColor: "#40aba7",
                                                color: "white",
                                            }}
                                            onClick={() =>
                                                handleOpenDelete([
                                                    rowData.article_id,
                                                ])
                                            }
                                        >
                                            Delete
                                        </button>
                                    ) : (
                                        <></>
                                    )
                                }
                            </Cell>
                        </Column>
                    </Table>
                    <div>
                        <SelectPicker
                            value={selected}
                            onChange={(e) => {
                                setSelected(e);
                                projectList.map((item) =>
                                    item.project_id == e
                                        ? setProjectName(item.project_name)
                                        : null
                                );
                                filterArticles(e);
                            }}
                            data={selectListData}
                            onClean={() => setFilterOn(false)}
                            style={{
                                width: 224,
                                marginLeft: "20px",
                                paddingBottom: "5px",
                            }}
                        />
                    </div>
                </div>
                {articlesList["total_pages"] ? (
                    <div className="d-flex justify-content-center">
                        <Pagination
                            pages={articlesList["total_pages"]}
                            limit={100}
                            activePage={activePage}
                            onChangePage={handlePageChange}
                        />
                    </div>
                ) : (
                    ""
                )}

                <br />
                {checkedKeys.length > 0 ? (
                    <div className="d-flex justify-content-center">
                        <Button
                            onClick={handleOpenBulkPublish}
                            style={{
                                backgroundColor: "#40aba7",
                                color: "white",
                            }}
                        >
                            Bulk Publish
                        </Button>
                        <Button
                            onClick={() => handleOpenDelete(checkedKeys)}
                            style={{
                                backgroundColor: "#40aba7",
                                color: "white",
                                marginLeft: "20px",
                            }}
                        >
                            Bulk Delete
                        </Button>
                        <Button
                            onClick={() => handleBulkDownload(checkedKeys)}
                            style={{
                                backgroundColor: "#40aba7",
                                color: "white",
                                marginLeft: "20px",
                            }}
                        >
                            Bulk Download
                        </Button>
                    </div>
                ) : (
                    <div className="d-flex justify-content-center">
                        <Button
                            appearance="primary"
                            disabled
                            style={{
                                backgroundColor: "#40aba7",
                                color: "white",
                                marginLeft: "20px",
                            }}
                        >
                            Bulk Publish
                        </Button>
                        <Button
                            appearance="primary"
                            disabled
                            style={{
                                backgroundColor: "#40aba7",
                                color: "white",
                                marginLeft: "20px",
                            }}
                        >
                            Bulk Delete
                        </Button>
                        <Button
                            appearance="primary"
                            disabled
                            style={{
                                backgroundColor: "#40aba7",
                                color: "white",
                                marginLeft: "20px",
                            }}
                        >
                            Bulk Download
                        </Button>
                    </div>
                )}
            </Container>
            <div>
                <Modal
                    overflow={true}
                    open={openEdit}
                    onClose={handleCloseEdit}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {editableArticle ? (
                                <Input
                                    placeholder="Article Title"
                                    defaultValue={editableArticle.title}
                                    onChange={(e) => {
                                        setEditableArticle({
                                            ...editableArticle,
                                            title: e,
                                        });
                                    }}
                                />
                            ) : (
                                ""
                            )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {editableArticle ? (
                            <Input
                                as="textarea"
                                placeholder="Article Body"
                                rows={20}
                                defaultValue={editableArticle.body}
                                onChange={(e) => {
                                    setEditableArticle({
                                        ...editableArticle,
                                        body: e,
                                    });
                                }}
                            />
                        ) : (
                            <div style={{ textAlign: "center" }}>
                                <Loader size="md" />
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={handleEditArticle}
                            appearance="primary"
                        >
                            Ok
                        </Button>
                        <Button onClick={handleCloseEdit} appearance="subtle">
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div>
                <Modal
                    overflow={true}
                    open={openPublish}
                    onClose={handleClosePublish}
                >
                    <Modal.Header>
                        <Modal.Title>Publish on Wordpress</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ paddingBottom: "250px" }}>
                        <div className="d-flex justify-content-center pb-3">
                            <InputPicker
                                data={wordpressAccounts}
                                style={{ width: 224 }}
                                value={selectedAccount}
                                onChange={setSelectedAccount}
                            />
                        </div>
                        <div className="d-flex justify-content-center">
                            <Input
                                placeholder="Wordpress Category"
                                style={{ width: 300 }}
                                value={wordpressCategory}
                                onChange={setWordpressCategory}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {selectedAccount ? (
                            <Button
                                onClick={handleSoloPublish}
                                appearance="primary"
                            >
                                Publish
                            </Button>
                        ) : (
                            <Button appearance="primary" disabled>
                                Publish
                            </Button>
                        )}
                        <Button
                            onClick={handleClosePublish}
                            appearance="subtle"
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div>
                <Modal
                    overflow={true}
                    open={openDelete}
                    onClose={() => setOpenDelete(!openDelete)}
                >
                    <Modal.Header>
                        <Modal.Title>Delete the Article(s)</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ paddingBottom: "10px" }}>
                        <p>This action is irresversible!</p>
                    </Modal.Body>
                    <Modal.Footer>
                        {deleteSelect ? (
                            <Button
                                onClick={() => {
                                    handleDelete(deleteSelect);
                                }}
                                appearance="primary"
                            >
                                Delete
                            </Button>
                        ) : (
                            <Button appearance="primary" disabled>
                                Delete
                            </Button>
                        )}
                        <Button
                            onClick={() => setOpenDelete(!openDelete)}
                            appearance="subtle"
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div>
                <Modal
                    overflow={true}
                    open={openBulkPublish}
                    onClose={handleCloseBulkPublish}
                >
                    <Modal.Header>
                        <Modal.Title>Publish on Wordpress</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ paddingBottom: "50px" }}>
                        <div className="d-flex justify-content-center pb-3">
                            <label>Wordpress Account</label>
                        </div>
                        <div className="d-flex justify-content-center pb-3">
                            <InputPicker
                                data={wordpressAccounts}
                                style={{ width: 224 }}
                                value={selectedAccount}
                                onChange={setSelectedAccount}
                            />
                        </div>
                        <div className="d-flex justify-content-center pb-3">
                            <label>Wordpress Category</label>
                        </div>
                        <div className="d-flex justify-content-center pb-3">
                            <Input
                                placeholder="Wordpress Category"
                                style={{ width: 300 }}
                                value={wordpressCategory}
                                onChange={setWordpressCategory}
                            />
                        </div>
                        <div className="d-flex justify-content-center pb-3">
                            <label>Schedule Type</label>
                        </div>
                        <div className="d-flex justify-content-center pb-3">
                            <InputPicker
                                defaultValue={rateTypeData[0].value}
                                data={rateTypeData}
                                style={{ width: 224 }}
                                value={rateType}
                                onChange={handleRateTypeChange}
                                onClean={() => {
                                    handleRateTypeChange("day");
                                }}
                            />
                        </div>
                        <div className="d-flex justify-content-center pb-3">
                            <label>Rate</label>
                        </div>
                        <div className="d-flex justify-content-center pb-3">
                            <div style={{ width: 200 }}>
                                <InputNumber
                                    defaultValue={3}
                                    max={max}
                                    min={1}
                                    value={rate}
                                    onChange={setRate}
                                />
                            </div>
                        </div>
                        {/* <p className="d-flex justify-content-center mt-3">
                            You can have a maximum of 24 posts per day or 168
                            posts per week.
                        </p> */}
                    </Modal.Body>
                    <Modal.Footer>
                        {selectedAccount ? (
                            <Button
                                onClick={handleBulkPublish}
                                appearance="primary"
                            >
                                Publish
                            </Button>
                        ) : (
                            <Button appearance="primary" disabled>
                                Publish
                            </Button>
                        )}
                        <Button
                            onClick={handleCloseBulkPublish}
                            appearance="subtle"
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default Articles;
