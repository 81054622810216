import Select from "react-select";
import { useEffect, useState } from "react";

const ArticleStructure = ({
    selectedOption,
    setSelectedOption,
    value,
    setValue,
    selectedToneOption,
    setSelectedToneOption,
    projectList,
    setProjectInfo,
    selected,
    setSelected,
    selectedBrandStyleOption,
    setSelectedBrandStyleOption,
}) => {
    const [projectOptions, setProjectOptions] = useState([
        { label: "default", value: "default", project_id: 0 },
    ]);
    const options = [
        { value: 300, label: "300" },
        { value: 450, label: "450" },
        { value: 600, label: "600" },
        { value: 750, label: "750" },
    ];

    // const toneOptions = [
    //     { value: "Assertive", label: "Assertive" },
    //     { value: "Authoritative", label: "Authoritative" },
    //     { value: "Caring", label: "Caring" },
    //     { value: "Casual", label: "Casual" },
    //     { value: "Cheerful", label: "Cheerful" },
    //     { value: "Coach", label: "Coach" },
    //     { value: "Coarse", label: "Coarse" },
    //     { value: "Colleague", label: "Colleague" },
    //     { value: "Conservative", label: "Conservative" },
    //     { value: "Conversational ", label: "Conversational " },
    //     { value: "Convincing", label: "Convincing" },
    //     { value: "Cooperative", label: "Cooperative" },
    //     { value: "Curious", label: "Curious" },
    //     { value: "Directive", label: "Directive" },
    //     { value: "Dry", label: "Dry" },
    //     { value: "Edgy", label: "Edgy" },
    //     { value: "Educator", label: "Educator" },
    //     { value: "Encouraging", label: "Encouraging" },
    //     { value: "Engaging", label: "Engaging" },
    //     { value: "Enthusiastic", label: "Enthusiastic" },
    //     { value: "Factual", label: "Factual" },
    //     { value: "Formal", label: "Formal" },
    //     { value: "Frank", label: "Frank" },
    //     { value: "Friendly", label: "Friendly" },
    //     { value: "Fun", label: "Fun" },
    //     { value: "Funny", label: "Funny" },
    //     { value: "Humorous", label: "Humorous" },
    //     { value: "Informal", label: "Informal" },
    //     { value: "Informative", label: "Informative" },
    //     { value: "Inspiring", label: "Inspiring" },
    //     { value: "Interesting", label: "Interesting" },
    //     { value: "Invested", label: "Invested" },
    //     { value: "Irreverent", label: "Irreverent" },
    //     { value: "Matter-of-fact", label: "Matter-of-fact" },
    //     { value: "Motivating", label: "Motivating" },
    //     { value: "Nostalgic", label: "Nostalgic" },
    //     { value: "Optimistic", label: "Optimistic" },
    //     { value: "Passionate", label: "Passionate" },
    //     { value: "Playful", label: "Playful" },
    //     { value: "Professional", label: "Professional" },
    //     { value: "Provocative", label: "Provocative" },
    //     { value: "Quirky", label: "Quirky" },
    //     { value: "Questioning", label: "Questioning" },
    //     { value: "Respectful", label: "Respectful" },
    //     { value: "Romantic", label: "Romantic" },
    //     { value: "Sarcastic", label: "Sarcastic" },
    //     { value: "Serious", label: "Serious" },
    //     { value: "Smart", label: "Smart" },
    //     { value: "Snarky", label: "Snarky" },
    //     { value: "Surprised", label: "Surprised" },
    //     { value: "Sympathetic", label: "Sympathetic" },
    //     { value: "Trendy", label: "Trendy" },
    //     { value: "Trustworthy", label: "Trustworthy" },
    //     { value: "Unapologetic", label: "Unapologetic" },
    //     { value: "Upbeat", label: "Upbeat" },
    //     { value: "Witty", label: "Witty" },
    //     { value: "Worried", label: "Worried" },
    // ];
    const toneOptions = [
        { value: "Neil Patel", label: "Neil Patel" },
        { value: "Tim Ferris", label: "Tim Ferris" },
        { value: "Tony Robbins", label: "Tony Robbins" },
        { value: "Lex Fridman", label: "Lex Fridman" },
        { value: "James Clear", label: "James Clear" },
    ];

    const brandStyleOptions = [
        { value: "Buzzfeed", label: "Buzzfeed" },
        { value: "Hubspot", label: "Hubspot" },
        { value: "Hootsuite", label: "Hootsuite" },
        { value: "Wikipedia", label: "Wikipedia" },
        { value: "Nytimes", label: "Nytimes" },
        { value: "CNN", label: "CNN" },
    ];

    useEffect(() => {
        setProjectOptions(
            projectList.map((item) => {
                return {
                    value: item.project_name,
                    label: item.project_name,
                    project_id: item.project_id,
                };
            })
        );
    }, [projectList]);

    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#F8F4F4",
            width: "300px",
        }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
    };

    const min = 1;
    const max = 7;

    const handleNumberChange = (event) => {
        const value = Math.max(min, Math.min(max, Number(event.target.value)));
        setValue(value);
    };

    return (
        <div className="container art-in-pad-top">
            <div className="input-box  mx-auto">
                <div className="d-flex pt-4">
                    <h5 className="ms-4 me-auto art-in-marg-10">
                        Customize your Article‘s Structure
                    </h5>
                    {/* <p className="me-4">Choose a topic for your article</p> */}
                </div>
                <div className="mx-4 art-in-horizontal-line"></div>
                <div className="row py-3">
                    <div className="col">
                        {/* <div className="d-flex justify-content-end">
                            <label>
                                <strong>Number of Subheadings</strong>
                            </label>
                        </div> */}
                        <div className="d-flex justify-content-end mt-4">
                            <label>
                                <strong>Select Project</strong>
                            </label>
                        </div>
                        <div className="d-flex justify-content-end mt-4 pt-2">
                            <label>
                                <strong>Number of Words</strong>
                            </label>
                        </div>
                        <div className="d-flex justify-content-end mt-4 pt-2">
                            <label>
                                <strong>Tone of Voice</strong>
                            </label>
                        </div>
                        <div className="d-flex justify-content-end mt-4 pt-2">
                            <label>
                                <strong>Brand Style</strong>
                            </label>
                        </div>
                        <div className="d-flex justify-content-end mt-4 pt-2">
                            <label>
                                <strong>Estimated Article Length</strong>
                            </label>
                        </div>
                    </div>
                    <div className="col">
                        {/* <div className="d-flex">
                            <input
                                type="number"
                                value={value}
                                onChange={handleNumberChange}
                            />
                        </div> */}
                        <div className="d-flex mt-3">
                            <Select
                                styles={colourStyles}
                                defaultValue={{
                                    label: "default",
                                    value: "default",
                                    project_id: 0,
                                }}
                                onChange={setSelected}
                                options={projectOptions}
                            />
                        </div>
                        <div className="d-flex mt-3">
                            <Select
                                styles={colourStyles}
                                defaultValue={selectedOption}
                                onChange={setSelectedOption}
                                options={options}
                            />
                        </div>
                        <div className="d-flex mt-3">
                            <Select
                                styles={colourStyles}
                                closeMenuOnSelect={false}
                                defaultValue={selectedToneOption}
                                onChange={setSelectedToneOption}
                                options={toneOptions}
                            />
                        </div>
                        <div className="d-flex mt-3">
                            <Select
                                styles={colourStyles}
                                defaultValue={selectedBrandStyleOption}
                                onChange={setSelectedBrandStyleOption}
                                options={brandStyleOptions}
                            />
                        </div>
                        <div className="d-flex mt-3 pt-1">
                            <label>~{value * selectedOption.value + 100}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ArticleStructure;
